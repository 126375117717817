.label-required:after {
  content: " *";
}

@media (max-width: 767px) {
  .pdf-container,
  .pdf-container canvas,
  .pdf-container .react-pdf__Page__textContent.textLayer,
  .pdf-container .react-pdf__Page__annotations.annotationLayer {
    width: 100% !important;
    height: auto !important;
  }
}

@media print {
  .pdf-container {
    display: none;
  }
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* toast style */
.Toastify__toast-container--top-center {
  top: 1em;
  left: 0;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.dynamic-toast {
  width: 500px;
  padding: 0;
}

@media (max-width: 767px) {
  .dynamic-toast {
    width: 100%;
    padding: 0;
  }
}

.toast-box span {
  padding-right: 20px;
}
